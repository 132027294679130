import ykb from './assets/ykb.jpg';
import feishu from './assets/feishu.svg';
import erp from './assets/erp.png';
import demo from './assets/demo.png';
import qs from 'qs';
import './App.css';
import { useEffect, useRef, useState } from 'react';

const wx = window.wx;

function App() {
    const auth = useRef();
    const existCode = window.location.href.includes('code=');
    const [ykbUrl, setYkbUrl] = useState('');

    useEffect(() => {
        if (!existCode) {
            // 自动点击生成授权code的链接，页面重新刷新
            auth.current.click();
        } else {
            fetch(
                `/api/yikuaibao/${
                    qs.parse(window.location.search, {
                        ignoreQueryPrefix: true,
                    }).code
                }`
            )
                .then((res) => res.json())
                .then((d) => {
                    if (d.url != 401) setYkbUrl(d.url);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    useEffect(() => {
        if (existCode) {
            fetch(`/api/signature/${encodeURIComponent(window.location.href)}`)
                .then((res) => res.json())
                .then((d) => {
                    console.log(d);
                    wx.config({
                        beta: true,
                        debug: false,
                        ...d,
                    });
                });
        }

        wx.error((res) => {
            console.error(res);
        });
    }, []);

    function handleClick(url) {
        // 移动端
        if (
            window.navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            window.open(url);
        } else {
            wx.invoke(
                'openDefaultBrowser',
                {
                    url,
                },
                function (res) {
                    console.log(res);
                }
            );
        }
    }
    return (
        <div className="app">
            <a
                style={{ display: 'none' }}
                ref={auth}
                href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww732938e4d48ca2f4&redirect_uri=https%3A%2F%2Fqiwei.yo-i.com.cn&response_type=code&scope=snsapi_base&state=#wechat_redirect"
            >
                网页授权链接
            </a>

            <div
                style={{
                    display: existCode ? 'none' : 'block',
                    textAlign: 'center',
                }}
            >
                加载中，请稍等
            </div>

            <header
                className="header"
                style={{ display: existCode ? 'flex' : 'none' }}
            >
                <a onClick={() => handleClick(ykbUrl)}>
                    <img src={ykb} />
                    {ykbUrl === '' ? '请重新登录' : '易快报'}
                </a>

                <a
                    onClick={() =>
                        handleClick('https://erp.yo-i.com.cn#target=out')
                    }
                >
                    <img src={erp} />
                    ERP
                </a>

                <a
                    onClick={() =>
                        handleClick('https://demo.yo-i.com.cn/yoi/apps/home/')
                    }
                >
                    <img src={demo} />
                    demo app
                </a>
                <a onClick={() => handleClick('https://okr.feishu.cn')}>
                    <img src={feishu} />
                    飞书OKR
                </a>
            </header>
        </div>
    );
}

export default App;
